<nav id="topnav" class="defaultscroll is-sticky" (window:scroll)="windowScroll()">
    <div class="container relative">
        <!-- Logo container-->
        
        <a *ngIf="navLight === true" class="logo" href="/">
            <span class="inline-block dark:hidden">
                <img src="assets/images/logo-dark.png" class="l-dark" height="24" alt="">
                <img src="assets/images/logo-dark.png" class="l-light" height="24" alt="">
            </span>
            <img src="assets/images/logo-light.png" height="24" class="hidden dark:inline-block" alt="">
        </a>

        <a *ngIf="!navLight === true" class="logo" routerLink="/">
            <img src="assets/images/logo-dark.png" class="inline-block dark:hidden" alt="">
            <img src="assets/images/logo-dark.png" class="hidden dark:inline-block" alt="">
        </a>
        <!-- End Logo container-->
        
        <!-- Start Mobile Toggle -->
        <div class="menu-extras">
            <div class="menu-item">
                <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
            </div>
        </div>
        <!-- End Mobile Toggle -->

        <!--Login button Start-->
        <!-- <ul class="buy-button list-none mb-0">
            <li class="inline mb-0">
                <a routerLink="auth-login" class="btn btn-icon bg-green-600 hover:bg-green-700 border-green-600 dark:border-green-600 text-white rounded-full"><i data-feather="user" class="h-4 w-4 stroke-[3]"></i></a>
            </li>
            <li class="sm:inline ps-1 mb-0 hidden">
                <a routerLink="auth-signup" class="btn bg-green-600 hover:bg-green-700 border-green-600 dark:border-green-600 text-white rounded-full">Signup</a>
            </li>
        </ul> -->
        <!--Login button End-->

        <div id="navigation" [style]="status  ? 'display: block;' : 'display: none;'">
            <!-- Navigation Menu-->   
            <ul [class]="childMessage === 'true' ? 'navigation-menu justify-end nav-light' : 'navigation-menu justify-end'">
                <li class="has-submenu parent-menu-item">
                    <a routerLink="/home" (click)="subManu('index-item')" class="sub-menu-item">Home</a>
                    <!-- <ul class="submenu" [ngClass]="['index-item','/index-two', '/index-three','/index-four','/index-five','/index-six','/index-seven'].includes(manuOpen) ? 'open' : ''">
                        <li><a routerLink="/" class="sub-menu-item">Hero One</a></li>
                        <li><a routerLink="/index-two" class="sub-menu-item">Hero Two</a></li>
                        <li><a routerLink="/index-three" class="sub-menu-item">Hero Three</a></li>
                        <li><a routerLink="/index-four" class="sub-menu-item">Hero Four</a></li>
                        <li><a routerLink="/index-five" class="sub-menu-item">Hero Five</a></li>
                        <li><a routerLink="/index-six" class="sub-menu-item">Hero Six</a></li>
                        <li><a routerLink="/index-seven" class="sub-menu-item">Hero Seven</a></li>
                    </ul> -->
                </li>
        
                <!-- <li><a routerLink="/buy" class="sub-menu-item">Buy</a></li>
        
                <li><a routerLink="/sell" class="sub-menu-item">Sell</a></li> -->

                <!-- <li class="has-submenu parent-parent-menu-item">
                    <a routerLink="javascript:void(0)" (click)="subManu('listing-item')">Listing</a><span class="menu-arrow"></span>
                    <ul class="submenu" [ngClass]="['/grid','/grid-sidebar', '/grid-map','/list','/list-sidebar','/list-map','/property-detail','grid-item','list-item', 'property-item','listing-item'].includes(manuOpen) ? 'open' : ''">
                        <li class="has-submenu parent-menu-item"><a routerLink="javascript:void(0)" (click)="subManu('grid-item')"> Grid View </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['grid-item','/grid','/grid-sidebar','/grid-map'].includes(manuOpen) ? 'open' : ''">
                                <li><a routerLink="/grid" class="sub-menu-item">Grid Listing</a></li>
                                <li><a routerLink="/grid-sidebar" class="sub-menu-item">Grid Sidebar </a></li>
                                <li><a routerLink="/grid-map" class="sub-menu-item">Grid With Map</a></li>
                            </ul> 
                        </li>
                        <li class="has-submenu parent-menu-item"><a routerLink="javascript:void(0)" (click)="subManu('list-item')"> List View </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['list-item','/list','/list-sidebar','/list-map'].includes(manuOpen) ? 'open' : ''">
                                <li><a routerLink="/list" class="sub-menu-item">List Listing</a></li>
                                <li><a routerLink="/list-sidebar" class="sub-menu-item">List Sidebar </a></li>
                                <li><a routerLink="/list-map" class="sub-menu-item">List With Map</a></li>
                            </ul>  
                        </li>
                        <li class="has-submenu parent-menu-item"><a routerLink="javascript:void(0)" (click)="subManu('property-item')"> Property Detail </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['list-item','/property-detail','property-item'].includes(manuOpen) ? 'open' : ''">
                                <li><a routerLink="/property-detail" class="sub-menu-item">Property Detail</a></li>
                            </ul>  
                        </li>
                    </ul>
                </li> -->

                <!-- <li class="has-submenu parent-parent-menu-item">
                    <a routerLink="javascript:void(0)" (click)="subManu('property-item')">Pages</a><span class="menu-arrow"></span>
                    <ul class="submenu" [ngClass]="['property-item','auth-item','utility-item','blog-item','special-item','/aboutus','/features','/pricing','/faqs','/auth-login','/auth-signup','/auth-re-password','/terms','/privacy','/blogs','/blog-sidebar','/blog-detail','/comingsoon','/maintenance','/404'].includes(manuOpen) ? 'open' : ''">
                        <li><a routerLink="/aboutus" class="sub-menu-item">About Us</a></li>
                        <li><a routerLink="/features" class="sub-menu-item">Features</a></li>
                        <li><a routerLink="/pricing" class="sub-menu-item">Pricing</a></li>
                        <li><a routerLink="/faqs" class="sub-menu-item">Faqs</a></li>
                        <li class="has-submenu parent-menu-item"><a routerLink="javascript:void(0)" (click)="subManu('auth-item')"> Auth Pages </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['auth-item','/auth-login','/auth-signup','/auth-re-password'].includes(manuOpen) ? 'open' : ''">
                                <li><a routerLink="/auth-login" class="sub-menu-item">Login</a></li>
                                <li><a routerLink="/auth-signup" class="sub-menu-item">Signup</a></li>
                                <li><a routerLink="/auth-re-password" class="sub-menu-item">Reset Password</a></li>
                            </ul>  
                        </li>
                        <li class="has-submenu parent-menu-item"><a routerLink="javascript:void(0)" (click)="subManu('utility-item')"> Utility </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['utility-item','/terms','/privacy'].includes(manuOpen) ? 'open' : ''">
                                <li><a routerLink="/terms" class="sub-menu-item">Terms of Services</a></li>
                                <li><a routerLink="/privacy" class="sub-menu-item">Privacy Policy</a></li>
                            </ul>  
                        </li>
                        <li class="has-submenu parent-menu-item"><a routerLink="javascript:void(0)" (click)="subManu('blog-item')"> Blog </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['blog-item','/blogs','/blog-sidebar','/blog-detail'].includes(manuOpen) ? 'open' : ''">
                                <li><a routerLink="/blogs" class="sub-menu-item"> Blogs</a></li>
                                <li><a routerLink="/blog-sidebar" class="sub-menu-item"> Blog Sidebar</a></li>
                                <li><a routerLink="/blog-detail" class="sub-menu-item"> Blog Detail</a></li>
                            </ul> 
                        </li>
                        <li class="has-submenu parent-menu-item"><a routerLink="javascript:void(0)" (click)="subManu('special-item')"> Special </a><span class="submenu-arrow"></span>
                            <ul class="submenu" [ngClass]="['special-item','/comingsoon','/maintenance','/404'].includes(manuOpen) ? 'open' : ''">
                                <li><a routerLink="/comingsoon" class="sub-menu-item">Comingsoon</a></li>
                                <li><a routerLink="/maintenance" class="sub-menu-item">Maintenance</a></li>
                                <li><a routerLink="/404" class="sub-menu-item">404! Error</a></li>
                            </ul>  
                        </li>
                    </ul>
                </li> -->
                <li><a routerLink="/buy" class="sub-menu-item">Buy</a></li>
                <li><a routerLink="/rent" class="sub-menu-item">Rent</a></li>
                <li><a routerLink="/about-us" class="sub-menu-item">About Us</a></li>
                <li><a routerLink="/contact" class="sub-menu-item">Contact</a></li>
            </ul><!--end navigation menu-->
        </div><!--end navigation-->
    </div><!--end container-->
</nav>
