<div class="container relative lg:my-24 my-16">
    <div class="grid grid-cols-1 pb-8 text-center">
        <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Meet The Agent Team</h3>

        <p class="text-slate-400 max-w-xl mx-auto">A great plateform to buy, sell and rent your properties without any agent or commisions.</p>
    </div><!--end grid-->

    <div class="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
        <div *ngFor="let item of teamData" class="lg:col-span-3 md:col-span-6">
            <div class="group text-center">
                <div class="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                    <img [src]="item.image" class="" alt="">
                    <div class="absolute inset-0 bg-gradient-to-b from-transparent to-black h-52 w-52 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out"></div>

                    <ul class="list-none absolute start-0 end-0 -bottom-20 group-hover:bottom-5 transition-all duration-500 ease-in-out">
                        <li class="inline"><a href="" class="btn btn-icon btn-sm rounded-full border border-green-600 bg-green-600 hover:border-green-600 hover:bg-green-600 text-white"><i data-feather="facebook" class="h-4 w-4"></i></a></li>
                        <li class="inline"><a href="" class="btn btn-icon btn-sm rounded-full border border-green-600 bg-green-600 hover:border-green-600 hover:bg-green-600 text-white"><i data-feather="instagram" class="h-4 w-4"></i></a></li>
                        <li class="inline"><a href="" class="btn btn-icon btn-sm rounded-full border border-green-600 bg-green-600 hover:border-green-600 hover:bg-green-600 text-white"><i data-feather="linkedin" class="h-4 w-4"></i></a></li>
                    </ul><!--end icon-->
                </div>

                <div class="content mt-3">
                    <a href="" class="text-xl font-medium hover:text-green-600 transition-all duration-500 ease-in-out">{{item.name}}</a>
                    <p class="text-slate-400">{{item.title}}</p>
                </div>
            </div>
        </div>
    </div><!--end grid-->
</div>
