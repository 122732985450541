const year = new Date().getFullYear();
export const environment = {
    production: false,
    isLive: false,
    sslEnable: false,
    defaultCountry: {
        id: 229,
        name: 'UAE',
        phoneCode: 971,
        code2: 'AE',
        code3: 'ARE'
    },
    pricingCurrency: {
        code: 'USD',
        conversion: 3.67,
        convertedCode: 'AED',
    },
    google: {
        apiKey: 'AIzaSyDpQCnRj7fVLP1gojbVm6eMFEQy8fYfH-Q'
    },
    apiUrl: 'https://api.realestateagentdubai.com/api/',
    baseUrl: 'http://macmillancrmapi.synergic360.com',
    // domain: 'lpcrm.synergic360.com',
    brandName: 'Mac Millan Trust',
    website: 'http://lpcrm.synergic360.com',
    copyrightText: '<small>Powered By <a class="mr-2 footer-link" href="https://www.onlinist.com/" target="_blanck"><strong>Onlinist</strong></a>&copy; Copyright ' + year + ' <strong class="aqua-text">CRM</strong>. All Rights Reserved.</small>',
};