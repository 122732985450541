import { Routes } from '@angular/router';
import { IndexComponent } from './pages/index/index/index.component';
import { IndexTwoComponent } from './pages/index/index-two/index-two.component';
import { IndexThreeComponent } from './pages/index/index-three/index-three.component';
import { IndexFourComponent } from './pages/index/index-four/index-four.component';
import { IndexFiveComponent } from './pages/index/index-five/index-five.component';
import { IndexSixComponent } from './pages/index/index-six/index-six.component';
import { IndexSevenComponent } from './pages/index/index-seven/index-seven.component';
import { BuyComponent } from './pages/buy/buy.component';
import { SellComponent } from './pages/sell/sell.component';
import { GridComponent } from './pages/listing/grid-view/grid/grid.component';
import { GridSidebarComponent } from './pages/listing/grid-view/grid-sidebar/grid-sidebar.component';
import { GridMapComponent } from './pages/listing/grid-view/grid-map/grid-map.component';
import { ListComponent } from './pages/listing/list-view/list/list.component';
import { ListSidebarComponent } from './pages/listing/list-view/list-sidebar/list-sidebar.component';
import { ListMapComponent } from './pages/listing/list-view/list-map/list-map.component';
import { PropertyDetailComponent } from './pages/listing/property-detail/property-detail/property-detail.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { FeaturesComponent } from './pages/features/features.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { FaqsComponent } from './pages/faqs/faqs.component';
import { AuthLoginComponent } from './pages/auth/auth-login/auth-login.component';
import { AuthSignupComponent } from './pages/auth/auth-signup/auth-signup.component';
import { AuthRePasswordComponent } from './pages/auth/auth-re-password/auth-re-password.component';
import { TermsComponent } from './pages/utility/terms/terms.component';
import { PrivacyComponent } from './pages/utility/privacy/privacy.component';
import { BlogsComponent } from './pages/blog/blogs/blogs.component';
import { BlogSidebarComponent } from './pages/blog/blog-sidebar/blog-sidebar.component';
import { BlogDetailComponent } from './pages/blog/blog-detail/blog-detail.component';
import { ComingsoonComponent } from './pages/special/comingsoon/comingsoon.component';
import { MaintenceComponent } from './pages/special/maintence/maintence.component';
import { ErrorComponent } from './pages/special/error/error.component';
import { ContactComponent } from './pages/contact/contact.component';
import { listingForBuyComponent } from './pages/listing-for-buy/listingForBuy.component';
import { listingForRentComponent } from './pages/listing-for-rent/listingForRent.component';

export const routes: Routes = [
    {
        'path': '',
        pathMatch: 'full',
        redirectTo: 'home'
    },
    { 'path': 'home', component: IndexComponent },
    // {'path':'index-two', component:IndexTwoComponent},
    // {'path':'index-three', component:IndexThreeComponent},
    // {'path':'index-four', component:IndexFourComponent},
    // {'path':'index-five', component:IndexFiveComponent},
    // {'path':'index-six', component:IndexSixComponent},
    // {'path':'index-seven', component:IndexSevenComponent},
    // {'path':'buy', component:BuyComponent},
    // {'path':'sell', component:SellComponent},
    // {'path':'grid', component:GridComponent},
    // {'path':'grid-sidebar', component:GridSidebarComponent},
    // {'path':'grid-map', component:GridMapComponent},
    // {'path':'list', component:ListComponent},
    // {'path':'list-sidebar', component:ListSidebarComponent},
    // {'path':'list-map', component:ListMapComponent},
    { 'path': 'property-details/:id', component: PropertyDetailComponent },
    { 'path': 'about-us', component: AboutusComponent },
    { 'path': 'buy', component: listingForBuyComponent },
    { 'path': 'rent', component: listingForRentComponent },
    // {'path':'features', component:FeaturesComponent},
    // {'path':'pricing', component:PricingComponent},
    // {'path':'faqs', component:FaqsComponent},
    // {'path':'auth-login', component:AuthLoginComponent},
    // {'path':'auth-signup', component:AuthSignupComponent},
    // {'path':'auth-re-password', component:AuthRePasswordComponent},
    // {'path':'terms', component:TermsComponent},
    // {'path':'privacy', component:PrivacyComponent},
    // {'path': 'blogs', component:BlogsComponent},
    // {'path':'blog-sidebar', component:BlogSidebarComponent},
    // {'path':'blog-detail', component:BlogDetailComponent},
    // {'path':'comingsoon', component:ComingsoonComponent},
    // {'path':'maintenance', component:MaintenceComponent},
    { 'path': '404', component: ErrorComponent },
    { 'path': 'contact', component: ContactComponent }

];
