<app-navbar />

<section class="relative md:pb-24 pb-16 mt-20">
    @if(isLoading){
    <div class="loaderOuter">
        <div class="loader"></div>
    </div>
    }
    <div class="container-fluid">
        <div class="md:flex mt-4">
            <div class="w-full p-1">
                <app-carousel [images]="currentPropertyDetailImages"></app-carousel>
            </div>
        </div><!--end flex-->

    </div><!--end container fluid-->

    <div class="container md:mt-24 mt-16">
        <div class="md:flex">
            <div class="lg:w-2/3 md:w-1/2 md:p-4 px-3">
                <h4 class="text-2xl font-medium text-green-600"> {{ currentPropertyDetail?.title }} </h4>

                <p class="text-slate-400 my-5"> {{currentPropertyDetail?.description}} </p>
                <div class="w-full leading-[0] border-0 mt-6">
                    <google-map height="450px" width="100%" [center]="center" [zoom]="zoom"
                        (mapClick)="moveMap($event)">
                        <map-marker *ngFor="let position of markerPositions" [position]="position" />
                    </google-map>
                </div>
            </div>

            <div class="lg:w-1/3 md:w-1/2 md:p-4 px-3 mt-8 md:mt-0">
                <div>
                    <div class="rounded-md bg-slate-50 dark:bg-slate-800 shadow dark:shadow-gray-700">
                        <div class="px-6 py-3">
                            <div class="flex justify-between items-center mt-4">
                                <h5 class="text-2xl font-medium">#REFERENCE</h5>
                                <span class="text-xl font-medium text-green-600"> {{currentPropertyDetail?.reference}}
                                </span>
                            </div>
                        </div>

                    </div>
                </div>


                <div>
                    <div
                        class="rounded-md bg-slate-50 dark:bg-slate-800 shadow dark:shadow-gray-700 mt-5 justify-center">
                        <div class="p-6 flex justify-center">
                            <div class="gold-backgorund-color flex justify-center align-middle" [style.width.px]="120"
                                [style.height.px]="120" [style.border-radius.%]="50">
                                @if(currentPropertyDetail?.photo_url) {
                                <img [src]="currentPropertyDetail?.photo_url" alt="agent image" [style.width.px]="120"
                                    [style.height.px]="120" [style.border-radius.%]="50">
                                } @else {
                                <h1 class="text-center my-auto text-5xl"> {{ currentPropertyDetail?.assigned_to?.first_name[0]
                                    }}{{
                                    currentPropertyDetail?.assigned_to?.last_name[0] }} </h1>
                                }
                            </div>
                        </div>

                        <div class="px-6 pb-6 flex flex-col justify-center">
                            <p class="text-4xl text-green-600 text-center"> {{ currentPropertyDetail?.assigned_to?.full_name
                                }} </p>

                            <div class="flex mt-3 mx-4 align-middle">
                                <i data-feather="phone" class="w-5 h-5 text-green-600 me-3"></i>
                                <div class="">
                                    <p> {{ currentPropertyDetail?.assigned_to?.mobile }} </p>
                                </div>
                            </div>
                            <div class="flex mx-4 align-middle">
                                <i data-feather="mail" class="w-5 h-5 text-green-600 me-3"></i>
                                <div class="">
                                    <p class=""> {{ currentPropertyDetail?.assigned_to?.email }} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="rounded-md bg-slate-50 dark:bg-slate-800 shadow dark:shadow-gray-700 mt-5">
                        <div class="p-6">

                            <div class="flex justify-between items-center mt-4">
                                <h5 class="text-2xl font-medium">Price:</h5>
                                <span class="text-xl font-medium text-green-600"> {{currentPropertyDetail?.price |
                                    currency:'AED ':'symbol':'0.2-2' }} </span>
                            </div>

                            <ul class="list-none mt-4">
                                @if(currentPropertyDetail?.city) {
                                    <li class="flex justify-between items-center">
                                        <span class="text-slate-400 text-sm">City</span>
                                        <span class="font-medium text-sm"> {{currentPropertyDetail?.city?.name}} </span>
                                    </li>
    
                                }
                                @if(currentPropertyDetail?.beds !== null) {
                                    <li class="flex justify-between items-center">
                                        <span class="text-slate-400 text-sm">{{getLabelforBedrooms(currentPropertyDetail?.beds)}}</span>
                                        <span class="font-medium text-sm"> {{getNumberOfBedrooms(currentPropertyDetail?.beds)}} </span>
                                    </li>
    
                                }
                                @if(currentPropertyDetail?.baths !== null) {
                                    <li class="flex justify-between items-center mt-2">
                                        <span class="text-slate-400 text-sm">Bathrooms</span>
                                        <span class="font-medium text-sm"> {{currentPropertyDetail?.baths}} </span>
                                    </li>
                                }

                                <li class="flex justify-between items-center ms-0">
                                    <span class="text-slate-400 text-sm"> Unit Type </span>
                                    <span class="font-medium text-sm"> {{currentPropertyDetail?.property_type}} </span>
                                </li>

                                <li class="flex justify-between items-center ms-0">
                                    <span class="text-slate-400 text-sm"> Furnished </span>
                                    <span class="font-medium text-sm"> {{currentPropertyDetail?.furnished}} </span>
                                </li>

                                <li class="flex justify-between items-center ms-0">
                                    <span class="text-slate-400 text-sm"> Parking </span>
                                    <span class="font-medium text-sm"> {{currentPropertyDetail?.parking}} </span>
                                </li>

                                <li class="flex justify-between items-center ms-0">
                                    <span class="text-slate-400 text-sm"> Plot Area </span>
                                    <span class="font-medium text-sm"> {{currentPropertyDetail?.plot_area}} </span>
                                </li>

                                <li class="flex justify-between items-center ms-0">
                                    <span class="text-slate-400 text-sm"> Build Up Area </span>
                                    <span class="font-medium text-sm"> {{currentPropertyDetail?.build_up_area | number: '0.2-2'}} </span>
                                </li>

                                <li class="flex justify-between items-center ms-0">
                                    <span class="text-slate-400 text-sm"> Price/sq-feet </span>
                                    <span class="font-medium text-sm"> {{currentPropertyDetail?.price_per_sq_feet}}
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div class="flex justify-center pb-6">
                            <div class="p-1 w-1/2">
                                <button onclick="enquire_modal.showModal();"
                                    class="btn bg-green-600 text-white rounded-md w-full" type="button">
                                    Enquire Now
                                </button>
                            </div> 
                        </div>
                    </div>

                    <dialog id="enquire_modal" #enquireModal class="modal modal-bottom sm:modal-middle">
                        <app-contact-form [isModal]="true" [listingInfo]="listingData"
                            (OPFormSubmitted)="submittedOPFn($event)"></app-contact-form>
                        <div
                            class="modal-action absolute end-0 pb-4 bg-white size-7 flex justify-center items-center rounded-full">
                            <form method="dialog" class="!leading-[0]">
                                <!-- if there is a button, it will close the modal -->
                                <button class="text-red-600"><i class="uil uil-multiply"></i></button>
                            </form>
                        </div>
                    </dialog>
                </div>
            </div>
        </div>
    </div>
</section>


<app-footer />