<app-navbar></app-navbar>
<div class="relative">
    @if(isLoading){
    <div class="loaderOuter">
        <div class="loader"></div>
    </div>
    }
    <section class="relative mt-20">
        <div class="container-fluid md:mx-4 mx-2">
            <div class="relative pt-40 pb-52 table w-full rounded-2xl shadow-md overflow-hidden bg-[url('/assets/images/bg/01.jpeg')] bg-no-repeat bg-center bg-cover"
                id="home">
                <div class="absolute inset-0 bg-black/60"></div>

                <div class="container relative">
                    <div class="grid grid-cols-1">
                        <div class="md:text-start text-center">
                            <h1 class="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6">
                                Your expert Real Estate advisers <br> for <span class="text-green-600">investment</span>
                                in the UAE and the GCC</h1>
                            <p class="text-white/70 text-xl max-w-xl">Whether you're looking to buy, to sell or to
                                invest, our expert team is here to provide you with unparalleled service support.</p>
                        </div>
                    </div><!--end grid-->
                </div><!--end container-->
            </div>
        </div><!--end Container-->
    </section>
    <section class="relative md:pb-24 pb-16">
        <div class="container relative">
            <div class="grid grid-cols-1 justify-center">
                <div class="relative -mt-32">
                    <div class="grid grid-cols-1">
                        @if(!isHideTabes){
                        <ul class="inline-block sm:w-fit w-full flex-wrap justify-center text-center p-4 bg-white dark:bg-slate-900 rounded-t-xl border-b dark:border-gray-800"
                            id="myTab" data-tabs-toggle="#StarterContent" role="tablist">
                            <li role="presentation" class="inline-block">
                                <button
                                    class="px-6 py-2 text-base font-medium rounded-md w-full transition-all duration-500 ease-in-out"
                                    id="buy-home-tab" (click)="formTab(1)"
                                    [ngClass]="activeindex === 1 ? 'text-white bg-green-600 hover:text-white' : '' ">Buy</button>
                            </li>
                            <li role="presentation" class="inline-block">
                                <button
                                    class="px-6 py-2 text-base font-medium rounded-md w-full  transition-all duration-500 ease-in-out"
                                    [ngClass]="activeindex === 2 ? 'text-white bg-green-600 hover:text-white' : '' "
                                    (click)="formTab(2)">Rent</button>
                            </li>
                        </ul>
                        }
                        <!-- @if(activeindex===1){ -->
                        <div id="StarterContent" [class.rounded-ss-none]="currentUrl == 'home'"
                            class="p-4 bg-white dark:bg-slate-900 rounded-se-none md:rounded-se-xl rounded-xl shadow-md dark:shadow-gray-700">
                            <div id="buy-home" role="tabpanel" aria-labelledby="buy-home-tab">
                                <form action="#">
                                    <div class="registration-form text-dark text-start">
                                        <div
                                            class="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6 lg:gap-y-2 md:gap-y-1">
                                            <div>
                                                <label
                                                    class="form-label font-medium text-slate-900 dark:text-white">Search:
                                                </label>
                                                <div class="filter-search-form relative filter-border mt-2">
                                                    <i class="uil uil-search icons"></i>
                                                    <input name="name" type="text" id="job-keyword"
                                                        class="form-input filter-input-box bg-gray-50 h-[60px] dark:bg-slate-800 border-0 ps-10"
                                                        placeholder="Search your keywords"
                                                        [(ngModel)]="searchData.title"
                                                        [ngModelOptions]="{standalone: true}">
                                                </div>
                                            </div>
                                            <div>
                                                <label for="buy-properties"
                                                    class="form-label font-medium text-slate-900 dark:text-white">Select
                                                    Location:</label>
                                                <div class="filter-search-form relative mt-2">
                                                    <img src="assets/images/svg/location_icon.svg" class="icons"
                                                        alt="">
                                                    <ng-select
                                                        class="form-input w-full filter-input-box bg-gray-50 h-[60px] dark:bg-slate-800 border-0 focus:ring-0 ps-10"
                                                        [items]="locationOptions" bindLabel="name" bindValue="id"
                                                        [(ngModel)]="searchData.location"
                                                        [ngModelOptions]="{standalone: true}"
                                                        (search)="onSearch($event)"
                                                        (clear)="onClearSelection('location')"
                                                        [loading]="locationLoader"
                                                        [placeholder]="getPlaceholder()">
                                                        <!-- [disabled]="!searchData.city" -->

                                                    </ng-select>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="w-100 relative h-4">
                                            @if(!isMoreFilter){
                                            <span class="moreFilter" (click)="isMoreFilter = true">More filter</span>
                                            }
                                            @else(){
                                            <span class="moreFilter" (click)="isMoreFilter = false">Hide filter</span>
                                            }
                                        </div>
                                        <div class="moreFilterWrapper" [ngClass]="isMoreFilter ? 'expand':'collapse'">
                                            <div
                                                class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6 lg:gap-y-2 md:gap-y-1">
                                                <div>
                                                    <label for="buy-properties"
                                                        class="form-label font-medium text-slate-900 dark:text-white">Select
                                                        City:</label>
                                                    <div class="filter-search-form relative mt-2 filter-border">
                                                        <img src="assets/images/svg/city_icon.svg" class="icons" alt="">
                                                        <ng-select
                                                            class="form-input w-full filter-input-box bg-gray-50 h-[60px] dark:bg-slate-800 border-0 focus:ring-0 ps-10"
                                                            [items]="citiesOptions" bindLabel="name" bindValue="id"
                                                            [(ngModel)]="searchData.city"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="cityChange($event)" [clearable]="false">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label for="buy-properties"
                                                        class="form-label font-medium text-slate-900 dark:text-white">Select
                                                        Type:</label>
                                                    <div class="filter-search-form relative filter-border mt-2">
                                                        <img src="assets/images/svg/types_icon.svg" class="icons"
                                                            alt="">
                                                        <ng-select
                                                            class="form-input w-full filter-input-box bg-gray-50 h-[60px] dark:bg-slate-800 border-0 focus:ring-0 ps-10"
                                                            [items]="TypeCat" bindLabel="name" bindValue="id"
                                                            [(ngModel)]="searchData.type"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="typeCateChange($event)"
                                                            (clear)="onClearSelection('type')">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label for="buy-properties"
                                                        class="form-label font-medium text-slate-900 dark:text-white">Select
                                                        Categories:</label>
                                                    <div class="filter-search-form relative filter-border mt-2">
                                                        <!-- <i class="uil uil-estate icons"></i> -->
                                                        <img src="assets/images/svg/category_icon.svg" class="icons"
                                                            alt="">
                                                        <ng-select
                                                            class="form-input w-full filter-input-box bg-gray-50 h-[60px] dark:bg-slate-800 border-0 focus:ring-0 ps-10"
                                                            [items]="property" bindLabel="name" bindValue="id"
                                                            [(ngModel)]="searchData.category"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (clear)="onClearSelection('category')">
                                                        </ng-select>
                                                    </div>
                                                </div>

                                                <div>
                                                    <label for="buy-properties"
                                                        class="form-label font-medium text-slate-900 dark:text-white">Select
                                                        Furnishing:</label>
                                                    <div class="filter-search-form relative mt-2">
                                                        <img src="assets/images/svg/chair-furniture.svg" class="icons"
                                                            alt="">

                                                        <ng-select
                                                            class="form-input w-full filter-input-box bg-gray-50 h-[60px] dark:bg-slate-800 border-0 focus:ring-0 ps-10"
                                                            [items]="FurnishingOptions" bindLabel="name" bindValue="id"
                                                            [(ngModel)]="searchData.furnished"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (clear)="onClearSelection('furnished')">
                                                        </ng-select>
                                                    </div>
                                                </div>

                                                
                                                <div>
                                                    <label for="buy-max-price"
                                                        class="form-label font-medium text-slate-900 dark:text-white">Bed(s)
                                                        :</label>
                                                    <div class="filter-search-form relative filter-border mt-2">
                                                        <!-- <img src="assets/images/svg/be" alt=""> -->
                                                        <i
                                                            class="uil uil-bed-double text-2xl me-2 text-green-600 icons"></i>
                                                        <input name="name" type="number" id="job-keyword"
                                                            class="form-input filter-input-box bg-gray-50 h-[60px] dark:bg-slate-800 border-0 ps-10"
                                                            placeholder="Enter no of beds" [(ngModel)]="searchData.beds"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </div>
                                                </div>
                                                <div>
                                                    <label for="buy-min-price"
                                                        class="form-label font-medium text-slate-900 dark:text-white">Min
                                                        Price
                                                        :</label>
                                                    <div class="filter-search-form relative mt-2 filter-border">
                                                        <img src="assets/images/svg/money-bill.svg" class="icons"
                                                            alt="">
                                                        <input name="name" type="number" id="job-keyword"
                                                            class="form-input filter-input-box bg-gray-50 h-[60px] dark:bg-slate-800 border-0 ps-10"
                                                            placeholder="Enter price in AED"
                                                            [(ngModel)]="searchData.min_price"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </div>
                                                </div>
                                                <div>
                                                    <label for="buy-max-price"
                                                        class="form-label font-medium text-slate-900 dark:text-white">Max
                                                        Price
                                                        :</label>
                                                    <div class="filter-search-form relative mt-2">
                                                        <img src="assets/images/svg/money-bill.svg" class="icons"
                                                            alt="">
                                                        <input name="name" type="number" id="job-keyword"
                                                            class="form-input filter-input-box bg-gray-50 h-[60px] dark:bg-slate-800 border-0 ps-10"
                                                            placeholder="Enter price in AED"
                                                            [(ngModel)]="searchData.max_price"
                                                            [ngModelOptions]="{standalone: true}">
                                                    </div>
                                                </div>



                                            </div>
                                        </div>

                                        <!--end grid-->
                                        <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                            <div class="mt-0 lg:mt-6 md:mt-3">
                                                <input type="submit" id="search-buy" name="search"
                                                    class="btn bg-green-600  border-green-600 duration-500 ease-in-out text-white searchbtn submit-btn w-full !h-12 rounded"
                                                    value="Search" (click)="search(true)">
                                            </div>
                                            <div class="mt-0 lg:mt-6 md:mt-3 ml-10-px ">
                                                <a href="javascript:void(0)" (click)="Clear()"
                                                    class="btn w-full lg:w-24 !h-12 bg-transparent clearbtn  border text-green-600 hover:text-white rounded-md">Clear</a>
                                            </div>
                                        </div>
                                    </div><!--end container-->
                                </form>
                            </div>
                        </div>
                        <section>
                            <section class="relative lg:py-24 py-16">
                                <div class="relative">
                                    <div class="grid gap-[30px]"
                                        [ngClass]="propertyList?.length > 0 ? 'lg:grid-cols-3 md:grid-cols-2': 'lg:grid-cols-1 md:grid-cols-1 place-items-center'">
                                        @for (item of propertyList; track item.id){
                                        <div
                                            class="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-700 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500">
                                            <div class="relative imageHolder">
                                                @if(item?.images.length > 0){
                                                <img [src]="item?.images[0]?.url" class="image" alt="">
                                                }
                                                @else{
                                                <img src="assets/images/no-image-placeholder.png" class="image" alt="">
                                                }
                                                <!-- <div class="absolute top-4 end-4">
                                                    <a href="javascript:void(0)"
                                                        class="btn btn-icon bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-full text-slate-100 dark:text-slate-700 focus:text-red-600 dark:focus:text-red-600 hover:text-red-600 dark:hover:text-red-600">
                                                        <i
                                                            class="mdi mdi-heart text-[20px]"></i></a>
                                                </div> -->
                                            </div>

                                            <div class="p-6">
                                                <div class="pb-6">
                                                    <a (click)="navigateToItemDetail(item.id)"
                                                        class="text-lg font-medium ease-in-out duration-500 cursor-pointer">{{item?.title}}</a>
                                                </div>

                                                <ul
                                                    class="py-6 border-y border-slate-100 dark:border-gray-800 flex items-center list-none">
                                                    <li class="flex items-center me-4">
                                                        <a title="No of Area">
                                                            <i
                                                                class="uil uil-compress-arrows text-2xl me-2 text-green-600"></i>
                                                        </a>
                                                        <span>{{item.build_up_area ? (item.build_up_area |
                                                            number:'1.0-0'): 'N/A'}}</span>
                                                    </li>

                                                    @if(item.property_type !== "Commercial") {
                                                    <li class="flex items-center me-4">
                                                        @if(item.beds > 0) {
                                                        <a title="Bedroom">
                                                            <i
                                                                class="uil uil-bed-double text-2xl me-2 text-green-600"></i>
                                                        </a>
                                                        }
                                                        @if(item.beds == 0) {
                                                        <a title="Studio">
                                                            <img src="assets/images/svg/studio.svg"
                                                                class="icons studio-icon" alt="Studio">
                                                        </a>
                                                        }

                                                        <span>{{getItemBeds(item)}}</span>
                                                    </li>

                                                    <li class="flex items-center">
                                                        <a title="Bathroom"><i
                                                                class="uil uil-bath text-2xl me-2 text-green-600"></i></a>
                                                        <span>{{item.baths}}</span>
                                                    </li>
                                                    }
                                                </ul>

                                                <ul class="pt-6 grid lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-2">

                                                    <li>
                                                        <span class="text-slate-400">Price</span>
                                                        <p class="text-sm line-height-15 font-medium">{{item.price ?
                                                            (item.price | number:'1.0-0') : 'N/A'}}</p>
                                                    </li>
                                                    <li>
                                                        <span class="text-slate-400">Type</span>
                                                        <p class="text-sm line-height-15 font-medium">
                                                            {{item?.category?.name}}</p>
                                                    </li>
                                                    <li>
                                                        <span class="text-slate-400">furnished</span>
                                                        <p class="text-sm line-height-15 font-medium">{{item?.furnished
                                                            || 'N/A'}}</p>
                                                    </li>
                                                    <li>
                                                        <span class="text-slate-400">City</span>
                                                        <p class="text-sm line-height-15 font-medium">{{item?.city?.name
                                                            || 'N/A'}}</p>
                                                    </li>
                                                    <li>
                                                        <span class="text-slate-400">Location</span>
                                                        <p class="text-sm line-height-15 font-medium">
                                                            {{item?.location?.name || 'N/A'}}
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        }
                                        @empty {
                                        <div *ngIf="!isLoading">
                                            <img src="assets/images/no_property_found.png" class="image noPropertyFound"
                                                alt="">
                                        </div>
                                        }

                                    </div><!--en grid-->
                                    @if(propertyList?.length > 0){
                                    <div class="mt-8 text-center">
                                        <!-- <div class="row mx-0 mt-3"> -->
                                        <!-- <div class="col">
                                                <div class="d-flex justify-content-center font-weight-bold text-center">
                                                    <pagination-controls
                                                        (pageChange)="changePage($event)"></pagination-controls>
                                                </div>
                                            </div> -->
                                        <!-- <button type="button" id="search-buy" [disabled]="response?.record?.current_page < response?.record?.last_page"
                                                        class="btn bg-green-600  border-green-600 duration-500 ease-in-out text-white rounded cursor-pointer"
                                                        value="Load More" (click)="loadMore()">Load More </button> -->
                                        <button type="button" id="search-buy"
                                            [disabled]=" response?.record?.current_page === response?.record?.last_page"
                                            [ngClass]="{
            'btn': true,
            'bg-green-600': true,
            'border-green-600': true,
            'duration-500': true,
            'ease-in-out': true,
            'text-white': true,
            'rounded': true,
            'cursor-pointer': response?.record?.current_page < response?.record?.last_page,
            'disabled': response?.record?.current_page === response?.record?.last_page
        }" value="Load More" (click)="loadMore()">
                                            Load More
                                        </button>

                                    </div>
                                    }
                                </div><!--end container-->
                            </section>
                        </section>
                        <!-- } -->

                        <!-- </div> -->
                    </div><!--end grid-->
                </div>
            </div><!--end grid-->
        </div><!--end container-->

        <!-- <app-about></app-about> -->

        <!-- <app-features></app-features> -->

        <!-- <app-properties></app-properties> -->

        <!-- <app-clients></app-clients> -->

        <!-- <app-get-in-touch></app-get-in-touch> -->

    </section>
</div>


<app-footer></app-footer>
<!-- <app-switcher></app-switcher> -->