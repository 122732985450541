@if(images?.length) {
<div class="flex flex-col justify-center mt-10">
    <div class="flex w-full p-4 cursor-pointer" (mouseover)="isHovered = true" (mouseout)="isHovered = false">
        <div class="previous-icon w-1/4 flex justify-center items-center"
            [ngClass]="{'opacity-90': isHovered, 'opacity-0': !isHovered}">
            <button (click)="prev()">
                <svg class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="#cd9a2f">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M15 18l-6-6l6-6" />
                </svg>
            </button>
        </div>
        <div class="image-container w-1/2 flex justify-center items-center opacity-75 image-height-carousel"
            [ngClass]="{'opacity-90 shadow-md': isHovered, 'opacity-75': !isHovered}">
            <img [src]="currentImage?.url" alt="" [style.height.px]="400" [style.object-fit]="'cover'">
        </div>
        <div class="next-icon text-center w-1/4 flex justify-center items-center"
            [ngClass]="{'opacity-90': isHovered, 'opacity-0': !isHovered}">
            <button (click)="next()">
                <svg class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="#cd9a2f">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M9 5l6 6-6 6" />
                </svg>
            </button>
        </div>
    </div>

    <div class="flex justify-center">
        <div class="previous-icon-displayer w-1/6 flex justify-center items-center">
            @if(images.length > this.displayedImages.length ) {
            <button (click)="onPrevious()">
                <svg class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="#cd9a2f">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M15 18l-6-6l6-6" />
                </svg>
            </button>
            }
        </div>
        <div [ngClass]="['gap-2', 'flex', 'justify-center', 'mx-6', 'w-full']">
            @for (image of displayedImages; track image?.id;) {
            <span class="cursor-pointer" >
                <img [src]="image.url" alt=""
                    [ngClass]="{ 'opacity-90': image == currentImage, 'opacity-35': image != currentImage }"
                    (click)="selectImage(image.id)" [style.width.px]="80" [style.height.px]="80" [style.object-fit]="'cover'">
            </span>
            }
        </div>
        <div class="next-icon text-center w-1/6 flex justify-center items-center" [@slide]="displayedImages">
            @if(images.length > this.displayedImages.length ) {
            <button (click)="onNext()">
                <svg class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="#cd9a2f">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M9 5l6 6-6 6" />
                </svg>
            </button>
            }
        </div>
    </div>
</div>
}

@else {
<div class="w-full flex justify-center mt-10">
    <div>
        <img src="assets\images\no-image-placeholder.png" alt="" [style.height.px]="400" [style.object-fit]="'cover'">
    </div>
</div>
}