import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';

import PropertyData from '../../../../data/property.json'

interface Property {
  id: number
  image: string
  name: string
  sqf: string
  beds: string
  baths: string
  price: string
}
@Component({
  selector: 'app-grid',
  standalone: true,
  imports: [CommonModule, NgSelectModule],
  templateUrl: './grid.component.html',
  styleUrl: './grid.component.scss'
})
export class GridComponent {
  @Input() data: any = null;
  propertyList: any
  property = [
    { id: 1, name: 'Houses' },
    { id: 2, name: 'Apartment' },
    { id: 3, name: 'Offices' },
    { id: 4, name: 'Townhome' },
  ]

  minPrice = [
    { id: 1, name: '500' },
    { id: 2, name: '1000' },
    { id: 3, name: '2000' },
    { id: 4, name: '3000' },
    { id: 5, name: '4000' }
  ]

  maxPrice = [
    { id: 1, name: '500' },
    { id: 2, name: '1000' },
    { id: 3, name: '2000' },
    { id: 4, name: '3000' },
    { id: 5, name: '4000' }
  ]
  ngOnInit() {
    if(this.data){
      this.propertyList = this.data.record.data
    }
    
  }
}
