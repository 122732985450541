const year = new Date().getFullYear();
export const environment = {
    production: true,
    isLive: true,
    sslEnable: true,
    apiUrl: 'https://api.macmillantrust.ae/api/', 
    baseUrl: 'https://api.macmillantrust.ae',
    domain: 'macmillantrust.ae',
    brandName: 'Mac Millan Trust',
    website: 'https://cms.macmillantrust.ae/',
    copyrightText: '<small>Powered By <a class="mr-2 footer-link" href="https://www.onlinist.com/" target="_blanck"><strong>Onlinist</strong></a>&copy; Copyright ' + year + ' <strong class="aqua-text">CRM</strong>. All Rights Reserved.</small>',
    defaultCountry: {
        id: 229,
        name: 'UAE',
        phoneCode: 971,
        code2: 'AE',
        code3: 'ARE'
    },
    pricingCurrency: {
        code: 'USD',
        conversion: 3.67,
        convertedCode: 'AED',
    },
    google: {
        apiKey: 'AIzaSyDpQCnRj7fVLP1gojbVm6eMFEQy8fYfH-Q',
        analyticsId: null as string | null
    },
};
