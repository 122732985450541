import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, VERSION, ViewChild, inject } from '@angular/core';
import { NavbarComponent } from '../../../../components/navbar/navbar.component';
import { FooterComponent } from '../../../../components/footer/footer.component';

import { LightgalleryModule } from 'lightgallery/angular';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { CarouselComponent } from '../../../../components/carousel/carousel.component';
import { ListingService } from '../../../index/services/listing.service';
import { ActivatedRoute } from '@angular/router';
import { GoogleMapsModule } from '@angular/google-maps';
import { ContactFormComponent } from '../../../../components/contact-form/contact-form.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-property-detail',
  standalone: true,
  imports: [CommonModule, NavbarComponent, FooterComponent, LightgalleryModule, CarouselComponent, GoogleMapsModule, ContactFormComponent],
  templateUrl: './property-detail.component.html',
  styleUrl: '../../../../../../node_modules/lightgallery/scss/lightgallery.scss'
})
export class PropertyDetailComponent implements OnInit {

  name = "Angular " + VERSION.major;
  settings = {
    counter: false,
  };
  isLoading = false;
  currentPropertyId: string | number | null = null;
  currentPropertyDetail: any;
  currentPropertyDetailImages: any[] = [];
  modalVisible = false;
  center: { lat: number | null, lng: number | null } = { lat: null, lng: null };
  markerPositions: Array<{ lat: number | null, lng: number | null }> = [];
  zoom: number = 17;
  @ViewChild('enquireModal', { static: true }) dialog!: ElementRef<HTMLDialogElement>;
  cdr = inject(ChangeDetectorRef);
  listingData: any;
  constructor(private listingService: ListingService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.currentPropertyId = this.route.snapshot.paramMap.get('id');
    if (this.currentPropertyId) {
      this.getPropertyDetail(this.currentPropertyId)
    }
    this.listingData = { listingId: this.currentPropertyId, type: 'sale' }

  }
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
    console.log(index, prevIndex);
  };

  getPropertyDetail(id: number | string) {
    this.isLoading = true;
    this.listingService.propertyDetail(id).subscribe((res: any) => {
      if (res.status == 'success') {
        this.currentPropertyDetail = res?.record;
        this.currentPropertyDetailImages = res?.record?.images;
        this.center = {
          lat: parseFloat(res?.record?.location?.latitude),
          lng: parseFloat(res?.record?.location?.longitude)
        };
        this.markerPositions = [];
        this.markerPositions.push(this.center);
        this.isLoading = false;
      }

    }, error => {
      this.isLoading = false;
    });
  }

  /*moveMap()*/
  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = (event.latLng.toJSON());
  }


  public handleAddressChange(place: google.maps.places.PlaceResult) {
    if (place.geometry && place.geometry.location) {
      let lat = place.geometry.location.lat();
      let lng = place.geometry.location.lng();
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }

      this.markerPositions = [{ lat: lat, lng: lng }];

      this.zoom = 17;
    }

  }

  submittedOPFn(value: any) {
    this.dialog.nativeElement.close();
  }

  getNumberOfBedrooms(beds: number | string | null): number | string {
    if(beds == null) {
      return 0;
    } else if (beds == 0) {
      return 1;
    }
    return beds;
  }

  getLabelforBedrooms(beds: number | string | null): number | string {
    if(beds == 0) {
      return 'Studio';
    }
    return 'Bedrooms';
  }
}
