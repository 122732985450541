import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { AboutComponent } from '../../components/about/about.component';
import { FeaturesComponent } from '../../components/features/features.component';
import { CountUpModule } from 'ngx-countup';
import { TeamComponent } from '../../components/team/team.component';
import { ClientTwoComponent } from '../../components/client-two/client-two.component';
import { GetInTouchComponent } from '../../components/get-in-tuch/get-in-touch.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { ListingService } from '../index/services/listing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
interface SearchData {
  category: any;
  title: string;
  min_price: string;
  max_price: string;
  furnished: any;
  beds: string;
  type: any;
  city: any;
  location: any;
  [key: string]: string | any;
}
@Component({
  selector: 'app-listingForBuy',
  standalone: true,
  imports: [CommonModule, NgSelectModule, FormsModule, NavbarComponent, AboutComponent, FeaturesComponent, CountUpModule, TeamComponent, ClientTwoComponent, GetInTouchComponent, FooterComponent],
  templateUrl: './listingForRent.component.html',
  styleUrl: './listingForRent.component.scss'
})
export class listingForRentComponent {
  data: any;
  propertyList: any;
  isHideTabes: boolean = false;
  response: any;
  locationLoader: boolean = false;
  optionArrayName = 
  { 
    location: 'location',


   }
  constructor(
    private listingService: ListingService,
    private router: Router,
    // private location: Location,
    private route: ActivatedRoute
  ) {

  }
  property = [
    { id: 0, name: 'All' },
  ]
  citiesOptions: any[] = []
  FurnishingOptions = [
    { id: 0, name: 'All' },
    { id: 'Fully Furnished', name: 'Fully Furnished' },
    { id: 'Semi Furnished', name: 'Semi Furnished' },
    { id: 'Unfurnished', name: 'Unfurnished' },
  ]
  TypeCat = [
    {
      id: 0,
      name: 'All'
    },
    {
      id: 'residential',
      name: 'Residential'
    },
    {
      id: 'commercial',
      name: 'Commercial'
    }
  ]
  excludingArray: any[] = ['All', 'Select Category', 'all']
  paginator: any = {
    page: 1,
    last_page: 1,
    from: 0,
    to: 0,
    limit: 10,
    total: 0,
    has_more_pages: false,
    is_first_page: true,
    itemsPerPage: 3,
    totalItems: 0,
    currentPage: 1,
  };

  activeindex: number = 1
  searchData: SearchData = {
    category: 0,
    title: '',
    min_price: '',
    max_price: '',
    furnished: '',
    beds: '',
    type: 0,
    // city: this.citiesOptions[0]?.id ? this.citiesOptions[0]?.id : '',
    city: 4,
    location: ''
  }
  locationOptions: any = [];
  isLoading: boolean = false;
  type: string = 'sale'
  currentUrl: string | null = '';
  isMoreFilter = false;
  ngOnInit() {
    this.currentUrl = this.route.snapshot.url.length ? this.route.snapshot.url[0].path : null;
    if (this.currentUrl) {
      if (this.currentUrl === "buy") {
        this.isHideTabes = true;
        this.type = 'sale'
      }
      if (this.currentUrl === "rent") {
        this.isHideTabes = true;
        this.type = 'rental'
      }
    }
    this.getCategoryDropdown();
    this.getCityDropdown();
    this.inItSearchData();
    this.getData();
  }

  getCityDropdown() {
    this.listingService.getCities().subscribe((resp: any) => {
      if (resp.status == 'success') {
        this.citiesOptions = resp.record;
        // this.citiesOptions.unshift({ id: 0, name: 'All' });
        // this.searchData.city = 4;
      }
    });
  }
  getCategoryDropdown(type: string = 'All') {

    this.listingService.getCategories(type).subscribe((resp: any) => {
      if (resp.status == 'success') {
        this.property = resp.record;
        this.property.unshift({ id: 0, name: 'All' })
      }
    });
  }
  getPlaceholder(): string {
    return this.searchData.location ? '' : 'Search at least three character';
  }
  inItSearchData() {
    this.searchData.category = 0;
    this.searchData.title = '';
    this.searchData.min_price = '';
    this.searchData.max_price = '';
    this.searchData.beds = '';
    this.searchData.furnished = 0;
    this.searchData.city = 4;
    this.searchData.type = 0;
    this.searchData.location = 0;

  }
  getData() {
    this.isLoading = true;
    let filter = this.removeEmptyValues(this.searchData);
    this.listingService.propertiesList(this.type, filter, this.paginator.itemsPerPage, this.paginator.currentPage).subscribe((res: any) => {
      if (res.status == 'success') {
        this.response = res;
        if (this.paginator.currentPage == 1) {
          this.propertyList = res.record.data;
        }
        else {
          this.propertyList = this.propertyList.concat(res.record.data);
        }
        // this.setPagination(res.record);
        this.isLoading = false;
      }
    }, (error: any) => {
      this.isLoading = false;
    })
  }
  formTab(index: number) {
    this.activeindex = index;
    if (this.activeindex == 2) {
      this.type = 'rental'
    }
    else if (this.activeindex == 1) {
      this.type = 'sale'
    }
    this.getData();
  }
  removeEmptyValues(data: any): any {
    return Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value !== '' && !this.excludingArray.includes(value))
    );
  }
  search(isSearch?: boolean) {
    if (isSearch) {
      this.paginator.currentPage = 1;
    }
    this.getData();
  }
  public changePage(page: number) {
    this.paginator.currentPage = page;
    this.search();
  }
  public setPagination(data: any) {
    this.paginator.itemsPerPage = data.per_page;
    this.paginator.totalItems = data.total;
    this.paginator.currentPage = data.current_page;
  }
  Clear() {
    this.inItSearchData();
    this.search(true);
  }


  navigateToItemDetail(id: number) {
    this.router.navigate(['/property-details', id]);
  }
  typeCateChange(value: any) {
    this.searchData.category = 0
    this.getCategoryDropdown(value.id);
  }

  getItemBeds(item: any) {
    if (item.beds == 0) {
      return 1;
    }

    return item.beds;
  }
  getLocation(keyWord?: string) {
    this.locationLoader = true;
    if (this.searchData.city) {
      this.listingService.getLocations(this.searchData.city, keyWord).subscribe((res: any) => {
        this.locationOptions = res.record;
        this.locationLoader = false;
        // this.locationOptions.unshift({ id: 0, name: 'All' });
      })
    }
  }
  cityChange(value: object) {
    // this.getLocation();
  }
  onClearSelection(key: any) {
    if (this.searchData.hasOwnProperty(key)) {
      this.searchData[key] = 0;
      this.clearOptionList(key);
    }
    this.getData()
  }
  clearOptionList(field: string) {
    switch (field) {
      case this.optionArrayName.location:
        this.locationOptions = [];
        break;
      // Add more cases for other fields if necessary
      default:
        break;
    }
  }
  loadMore() {
    this.paginator.currentPage++;
    this.getData()
  }
  onSearch(term: any) {
    if (term.term && term.term.length > 3) {
      this.getLocation(term.term);
    } else {
      this.locationOptions = []; // Clear options if the search term is empty
    }
  }
  // searchLocations(term)
}
