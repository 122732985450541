import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment.lhe';
import { phoneNumberValidator } from '../../CustomValidators/PhoneNumberValidator';
import { CommonModule } from '@angular/common';
import { ListingService } from '../../pages/index/services/listing.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss'
})
export class ContactFormComponent implements OnInit {

  enquiryForm!: FormGroup;
  phoneCode: any = '+' + environment.defaultCountry.phoneCode;

  @Input() isModal: boolean = false;
  @Input() listingInfo: any = '';
  @Input() ownLoading: boolean = true;
  isLoading: boolean = false;
  @Output() OPFormSubmitted = new EventEmitter<any>();
  @Output() OPLoading = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private listingService: ListingService,
    private toast: ToastrService
  ) { }

  ngOnInit() {
    this.initializeForm()
  }

  initializeForm() {
    this.enquiryForm = this.formBuilder.group(
      {
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        mobile: [this.phoneCode, [Validators.required, phoneNumberValidator()]],
        note: [''],
        listing_id: [this.listingInfo?.listingId ? this.listingInfo?.listingId : null],
        type: [this.listingInfo?.type ? this.listingInfo?.type : null]
      }
    );
  }

  saveEnquiryForm() {
    this.enquiryForm.markAllAsTouched();
    if (this.enquiryForm.invalid) {
      return;
    }
    this.OPLoading.emit(true);
    let data = this.enquiryForm.getRawValue();
    this.isLoading = true;
    this.listingService.inquiry(this.isModal ? 'inquiry' : 'contact', data).subscribe((res: any) => {
      if (res.status == 'success') {
        console.log('success');
        this.isLoading = false;
        this.OPFormSubmitted.emit();
        this.formReset();
        this.OPLoading.emit(false);
        let message = this.isModal ? 'Enquiry submitted successfully!. Our team will contact you soon' : 'Thank you for contacting us!. Our team will contact you soon';
        this.toast.success(message, 'Submitted');
      }
    }, error => {
      this.toast.error('Error submitting the form. Please try again later.');
      this.OPFormSubmitted.emit();
      this.OPLoading.emit(false);
    });
  }
  formReset() {
    this.initializeForm();
  }
}
