<div class="container relative lg:mt-24 mt-16">
    <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
        <div class="md:col-span-5">
            <div class="relative">
                <img src="assets/images/about.jpeg" class="rounded-xl shadow-md h-335" alt="">
                <div class="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                    <!-- <a href="javascript:void(0)" data-type="youtube" data-id="yba7hPeTSjk" onclick="my_modal_6.showModal()" 
                        class="lightbox h-20 w-20 rounded-full shadow-md dark:shadow-gyay-700 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-green-600">
                        <i class="mdi mdi-play inline-flex items-center justify-center text-2xl">
                        </i>
                    </a> -->
                </div>
                <!-- <dialog id="my_modal_6" class="modal modal-bottom sm:modal-middle">
                    <iframe width="750" height="400"
                    src="https://www.youtube.com/embed/yba7hPeTSjk?playlist=yba7hPeTSjk&loop=1">
                    </iframe>
                    <div class="modal-action absolute end-0 top-0 bg-white size-7 flex justify-center items-center rounded-full">
                        <form method="dialog" class="!leading-[0]">
                          <button ><i class="uil uil-multiply"></i></button>
                        </form>
                      </div>
                </dialog> -->
            </div>
        </div><!--end col-->

        <div class="md:col-span-7">
            <div class="lg:ms-4">
                <h4 class="mb-6 md:text-3xl text-2xl lg:leading-normal leading-normal font-semibold">Expert, Concise, Efficient</h4>
                <p class="text-slate-400 max-w-xl">
                    Mac Millan Trust Investment Company is a prestigious European-owned real estate agency located in Dubai. We are proud to be approved by RERA (Real Estate Regulatory Agency) and have established strong partnerships with all major Dubai real estate developers.
                    At Mac Millan Trust, we understand that buying, selling, or renting property in Dubai can be a complex process. That's why our team of experienced professionals is dedicated to providing you with expert guidance and personalized service every step of the way.
                    Whether you're looking to invest in a luxury villa, find the perfect apartment for your family, or lease commercial space for your business, we are here to help. Our commitment is to make your Dubai real estate vision a reality.</p>
                
                <!-- <div class="mt-4">
                    <a href="" class="btn bg-green-600 hover:bg-green-700 text-white rounded-md mt-3">Learn More </a>
                </div> -->
            </div>
        </div><!--end col-->
    </div><!--end grid-->
</div>
