import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { trigger, transition, query, style, animate, group } from '@angular/animations';

export const slide = trigger('slide', [
  transition(':increment', [
    style({ transform: 'translateX(-100%)' }),
    animate('0.5s ease-out', style({ transform: 'translateX(0)' })),
  ]),
  transition(':decrement', [
    style({ transform: 'translateX(100%)' }),
    animate('0.5s ease-out', style({ transform: 'translateX(0)' })),
  ]),
]);

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  animations: [slide],
})

export class CarouselComponent implements OnChanges, OnInit {

  isHovered: boolean = false;
  @Input() images: any[] = [];
  currentImage: any;
  currentIndex: number = 0;
  displayedImages: any[] = [];

  minWindowRequired: number = 1200;
  maxWindowRequired: number = 1500;

  windowSize: { width: number, height: number } = { width: window.innerWidth, height: window.innerHeight };

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['images']?.currentValue?.length) {
      this.currentImage = changes['images']?.currentValue[0]
      if (window.innerWidth < this.minWindowRequired) {
        this.lowerScreens(window.innerWidth, changes['images']?.currentValue);
      } else if (window.innerWidth > this.maxWindowRequired) {
        this.higherScreens(window.innerWidth, changes['images']?.currentValue);
      } else {
        this.displayedImages = changes['images']?.currentValue.slice(0, 12);
      }
    }
  }

  ngOnInit(): void { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowSize.width = window.innerWidth;

    if (window.innerWidth < this.minWindowRequired) {
      this.lowerScreens(window.innerWidth, this.images);
    } else if (window.innerWidth > this.maxWindowRequired) {
      this.higherScreens(window.innerWidth, this.images);
    } else {
      this.displayedImages = this.images.slice(0, 12);
    }
  }

  next() {
    let currentImageIndex = this.images.findIndex(image => image == this.currentImage);
    if (currentImageIndex !== -1 && currentImageIndex !== this.images.length - 1) {
      this.currentImage = this.images[currentImageIndex + 1];
    } else {
      return;
    }
  }

  prev() {
    let currentImageIndex = this.images.findIndex(image => image == this.currentImage);
    if (currentImageIndex > 0) {
      this.currentImage = this.images[currentImageIndex - 1];
    } else {
      return;
    }
  }

  onNext() {
    if ((this.images.indexOf(this.displayedImages[this.displayedImages.length - 1]) < (this.images.length - 1))) {
      this.displayedImages.shift();
      let lastDisplayImageIndex = this.images.findIndex(image => image === this.displayedImages[this.displayedImages.length - 1]);
      this.displayedImages.push(this.images[lastDisplayImageIndex + 1]);
    }
  }

  onPrevious() {
    if (this.images.indexOf(this.displayedImages[0]) > 0) {
      this.displayedImages.pop();
      let lastDisplayImageIndex = this.images.findIndex(image => image === this.displayedImages[0]);
      this.displayedImages.unshift(this.images[lastDisplayImageIndex - 1]);
    }
  }

  selectImage(id: number) {
    this.currentImage = this.images.find(image => image.id == id);
  }

  lowerScreens(currentWindowWidth: number, images: any[]) {
    const differenceInWindowSizes = this.minWindowRequired - currentWindowWidth;
    const sizeLength = (differenceInWindowSizes / 70).toString();
    if (images.length > parseInt(sizeLength)) {
      this.displayedImages = images.slice(0, 12 - parseInt(sizeLength));
    } else {
      this.displayedImages = images.map(image => ({ ...image }));
    }
  }

  higherScreens(currentWindowWidth: number, images: any[]) {
    const differenceInWindowSizes = currentWindowWidth - this.maxWindowRequired;
    const sizeLength = (differenceInWindowSizes / 70).toString();
    if (images.length > parseInt(sizeLength)) {
      this.displayedImages = images.slice(0, 12 + parseInt(sizeLength));
    } else {
      this.displayedImages = images.map(image => ({ ...image }));
    }
  }
}
