<div class="container relative lg:mt-24 mt-16">
    <div class="grid grid-cols-1 pb-8 text-center">
        <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Featured Properties</h3>

        <p class="text-slate-400 max-w-xl mx-auto">A great plateform to buy, sell and rent your properties without any agent or commisions.</p>
    </div><!--end grid-->

    <div class="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
        <div *ngFor="let item of propertylist" class="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-700 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500">
            <div class="relative">
                <img [src]="item.image"alt="">

                <div class="absolute top-4 end-4">
                    <a href="javascript:void(0)" class="btn btn-icon bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-full text-slate-100 dark:text-slate-700 focus:text-red-600 dark:focus:text-red-600 hover:text-red-600 dark:hover:text-red-600"><i class="mdi mdi-heart text-[20px]"></i></a>
                </div>
            </div>

            <div class="p-6">
                <div class="pb-6">
                    <a href="/property-detail/{{item.id}}" class="text-lg hover:text-green-600 font-medium ease-in-out duration-500">{{item.name}}</a>
                </div>

                <ul class="py-6 border-y border-slate-100 dark:border-gray-800 flex items-center list-none">
                    <li class="flex items-center me-4">
                        <i class="uil uil-compress-arrows text-2xl me-2 text-green-600"></i>
                        <span>{{item.sqf}}</span>
                    </li>

                    <li class="flex items-center me-4">
                        <i class="uil uil-bed-double text-2xl me-2 text-green-600"></i>
                        <span>{{item.beds}}</span>
                    </li>

                    <li class="flex items-center">
                        <i class="uil uil-bath text-2xl me-2 text-green-600"></i>
                        <span>{{item.baths}}</span>
                    </li>
                </ul>

                <ul class="pt-6 flex justify-between items-center list-none">
                    <li>
                        <span class="text-slate-400">Price</span>
                        <p class="text-lg font-medium">{{item.price}}</p>
                    </li>

                    <li>
                        <span class="text-slate-400">Rating</span>
                        <ul class="text-lg font-medium text-amber-400 list-none">
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline"><i class="mdi mdi-star"></i></li>
                            <li class="inline text-black dark:text-white">5.0(30)</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div><!--end property content-->

    </div><!--en grid-->

    <div *ngIf="moreOption === true" class="md:flex justify-center text-center mt-6">
        <div class="md:w-full">
            <a href="/grid" class="btn btn-link text-green-600 hover:text-green-600 after:bg-green-600 transition duration-500">View More Properties <i class="uil uil-arrow-right ms-1"></i></a>
        </div>
    </div>
</div>
