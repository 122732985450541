<app-navbar />

<div class="relative">
    @if(isLoading){
    <div class="loaderOuter">
        <div class="loader"></div>
    </div>
    }
    <div class="container-fluid mt-20">
        

        <section class="lg:pb-24 lg:pt-6 pb-16 pt6">
            <div class="container relative">
                <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                    <div class="lg:col-span-7 md:col-span-6">
                        <img src="assets/images/svg/contact.svg" alt="">
                    </div>

                    <div class="lg:col-span-5 md:col-span-6">
                        <div class="lg:me-5">
                            <app-contact-form (OPLoading)="loadingOPFn($event)" [ownLoading]="false"></app-contact-form>
                        </div>
                    </div>
                </div>
            </div><!--end container-->

            <div class="container relative lg:mt-24 mt-16">
                <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                    <div class="text-center px-6">
                        <div class="relative overflow-hidden text-transparent -m-3">
                            <i data-feather="hexagon" class="h-32 w-32 fill-green-600/5 mx-auto"></i>
                            <div
                                class="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-green-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                                <i class="uil uil-phone"></i>
                            </div>
                        </div>

                        <div class="content mt-7">
                            <h5 class="title h5 text-xl font-medium">Phone</h5>
                            <p class="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and
                                benefit</p>

                            <div class="mt-5">
                                <a href="tel:+971 58 550 0323"
                                    class="btn btn-link text-green-600 transition duration-500">+971 58 550 0323</a>
                            </div>
                        </div>
                    </div>

                    <div class="text-center px-6">
                        <div class="relative overflow-hidden text-transparent -m-3">
                            <i data-feather="hexagon" class="h-32 w-32 fill-green-600/5 mx-auto"></i>
                            <div
                                class="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-green-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                                <i class="uil uil-envelope"></i>
                            </div>
                        </div>

                        <div class="content mt-7">
                            <h5 class="title h5 text-xl font-medium">Email</h5>
                            <p class="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and
                                benefit</p>

                            <div class="mt-5">
                                <a href="mailto:info@macmillantrust.com"
                                    class="btn btn-link text-green-600 transition duration-500">info&#64;macmillantrust.com</a>
                            </div>
                        </div>
                    </div>

                    <div class="text-center px-6">
                        <div class="relative overflow-hidden text-transparent -m-3">
                            <i data-feather="hexagon" class="h-32 w-32 fill-green-600/5 mx-auto"></i>
                            <div
                                class="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-green-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                                <i class="uil uil-map-marker"></i>
                            </div>
                        </div>

                        <div class="content mt-7">
                            <h5 class="title h5 text-xl font-medium">Location</h5>

                            <p class="text-slate-400 mt-3">The Onyx Tower 2, Office 502, The Greens <br>Dubai, United
                                Arab Emirates</p>
                            <div class="mt-5">
                                <a href="https://maps.app.goo.gl/x5pde9okhLjgfuSA9" data-type="iframe" target="_blank"
                                    class="video-play-icon read-more lightbox btn btn-link text-green-600 transition duration-500">View
                                    on Google map</a>
                            </div>
                        </div>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->
        </section>
        <div class="grid grid-cols-1">
            <div class="w-full leading-[0] border-0">

                <iframe
                    src="https://www.google.com/maps/embed/v1/search?q=The+Onyx+Tower+2&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                    width="100%" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
            </div><!--end grid-->
        </div>
    </div>

    <app-footer />