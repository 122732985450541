<div class="container relative lg:mt-24 mt-16">
    <div class="grid grid-cols-1 text-center">
        <h3 class="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-medium text-black dark:text-white">Have Question ? Get in touch!</h3>

        <p class="text-slate-400 max-w-xl mx-auto">A great plateform to buy, sell and rent your properties</p>
    
        <div class="mt-6">
            <a href="/contact" class="btn bg-green-600 text-white rounded-md"><i class="uil uil-phone align-middle me-2"></i> Contact us</a>
        </div>
    </div>
</div>
