<app-navbar [childMessage]="'true'" [navLight]="true"/>

<section class="relative table w-full py-32 lg:py-36 bg-[url('/assets/images/bg/01.jpg')] bg-no-repeat bg-center bg-cover bgImage">
    <div class="absolute inset-0 bg-black opacity-80"></div>
    <div class="container relative">
        <div class="grid grid-cols-1 text-center mt-10">
            <h3 class="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">About Us</h3>
        </div><!--end grid-->
    </div><!--end container-->
</section><!--end section-->
<div class="relative">
    <div class="shape overflow-hidden z-1 text-white dark:text-slate-900">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>

<section class="relative lg:pb-24 pb-16">

    <app-about/>

    <app-features/>

</section>

<section class="relative py-24 bg-[url('/assets/images/bg/01.jpg')] bg-no-repeat bg-center bg-fixed bg-cover">
    <div class="absolute inset-0 bg-black/60"></div>
    <div class="container relative">
        <div class="grid md:grid-cols-3 grid-cols-1 items-center">
            <div *ngFor="let item of counterData" class="counter-box text-center">
                <h1 class="text-white lg:text-5xl text-4xl font-semibold mb-2"><span class="counter-value" [countUp]="item.target">0</span>+</h1>
                <h5 class="counter-head text-white text-lg font-medium">{{item.name}}</h5>
            </div><!--end counter box-->
        </div>
    </div><!--end container-->
</section>

<section class="relative lg:pb-24 pb-16">
    <!-- <app-team/> -->

    <!-- <app-client-two/> -->

   <app-get-in-touch/>
</section>

<app-footer/>

