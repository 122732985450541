<footer class="relative bg-slate-900 dark:bg-slate-800">
    <div class="container relative">
        <div class="grid grid-cols-1">
            <div class="relative py-16">
                <!-- Subscribe -->
                <div class="relative w-full">
                    <div class="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div class="lg:col-span-2 md:col-span-4">
                            <h5 class="tracking-[1px] text-gray-100 font-semibold">Company</h5>
                            <ul class="list-none footer-list mt-6">
                                <li><a href="/about-us"
                                        class="text-slate-300 hover:text-slate-400 duration-500 ease-in-out"><i
                                            class="uil uil-angle-right-b me-1"></i> About us</a></li>
                                <li class="mt-[10px] ms-0"><a href="/contact"
                                        class="text-slate-300 hover:text-slate-400 duration-500 ease-in-out"><i
                                            class="uil uil-angle-right-b me-1"></i> Contact us</a></li>
                            </ul>
                        </div><!--end col-->

                        <div class="lg:col-span-2 md:col-span-4">
                            <h5 class="tracking-[1px] text-gray-100 font-semibold">Use full Links</h5>
                            <ul class="list-none footer-list mt-6">
                                <!-- <li><a href="/terms" class="text-slate-300 hover:text-slate-400 duration-500 ease-in-out"><i class="uil uil-angle-right-b me-1"></i> Terms of Services</a></li> -->
                                <!-- <li class="mt-[10px] ms-0"><a href="/privacy" class="text-slate-300 hover:text-slate-400 duration-500 ease-in-out"><i class="uil uil-angle-right-b me-1"></i> Privacy Policy</a></li> -->
                                <li class="mt-[10px] ms-0"><a href="/buy"
                                        class="text-slate-300 hover:text-slate-400 duration-500 ease-in-out"><i
                                            class="uil uil-angle-right-b me-1"></i> Buy Property </a></li>
                                <li class="mt-[10px] ms-0"><a href="/rent"
                                        class="text-slate-300 hover:text-slate-400 duration-500 ease-in-out"><i
                                            class="uil uil-angle-right-b me-1"></i> Rent Property </a></li>
                            </ul>
                        </div><!--end col-->

                        <div class="lg:col-span-3 md:col-span-4">
                            <h5 class="tracking-[1px] text-gray-100 font-semibold">Contact Details</h5>


                            <div class="flex mt-6">
                                <i data-feather="map-pin" class="w-5 h-5 text-green-600 me-3"></i>
                                <div class="">
                                    <h6 class="text-gray-300 mb-2"> The Onyx Tower 2, <br> Office 502, The Greens,
                                        <br>Dubai United Arab Emirates</h6>
                                </div>
                            </div>

                            <div class="flex mt-3">
                                <i data-feather="mail" class="w-5 h-5 text-green-600 me-3"></i>
                                <div class="">
                                    <a href="mailto:info@macmillantrust.com"
                                        class="text-slate-300 hover:text-slate-400 duration-500 ease-in-out">info&#64;macmillantrust.com</a>
                                </div>
                            </div>

                            <div class="flex mt-3">
                                <i data-feather="phone" class="w-5 h-5 text-green-600 me-3"></i>
                                <div class="">
                                    <a href="tel:+971 58 550 0323"
                                        class="text-slate-300 hover:text-slate-400 duration-500 ease-in-out">+971 58 550
                                        0323</a>
                                </div>
                            </div>
                        </div><!--end col-->
                        <div class="lg:col-span-5 md:col-span-4">
                            <div class="w-full leading-[0] border-0">

                                <iframe
                                    src="https://www.google.com/maps/embed/v1/search?q=The+Onyx+Tower+2&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                                    width="100%" height="200" frameborder="0" style="border:0" allowfullscreen></iframe>
                            </div>
                        </div><!--end col-->
                    </div><!--end grid-->
                </div>
                <!-- Subscribe -->
            </div>
        </div>
    </div><!--end container-->

    <div class="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
        <div class="container relative text-center">
            <div class="grid md:grid-cols-2 items-center gap-6">
                <div class="md:text-start text-center">
                    <p class="mb-0 text-gray-300">© {{date}} Mac Millan Trust - Designed & Developed by <a
                            href="https://onlinist.com/" target="_blank" class="text-reset text-green-600">ONLINIST</a>
                    </p>
                </div>

                <ul class="list-none md:text-end text-center">
                    <li class="inline"><a
                            href="https://www.linkedin.com/in/macmillantrust?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                            target="_blank"
                            class="btn SSLinks btn-icon btn-sm text-green-600 hover:text-white border border-gray-800 rounded-md "><i
                                data-feather="linkedin" class="h-5 w-5"></i></a></li>
                    <li class="inline"><a href="https://www.facebook.com/people/Macmillan-Trust/61555281522582/" target="_blank"
                            class="btn SSLinks btn-icon btn-sm text-green-600 hover:text-white border border-gray-800 rounded-md "><i
                                data-feather="facebook" class="h-5 w-5"></i></a></li>
                    <li class="inline"><a
                            href="https://www.instagram.com/macmillantrust?igsh=MXc1YzAzcmhubDU1ZA%3D%3D&utm_source=qr"
                            target="_blank"
                            class="btn SSLinks btn-icon btn-sm text-green-600 hover:text-white border border-gray-800 rounded-md "><i
                                data-feather="instagram" class="h-5 w-5"></i></a></li>
                    <li class="inline"><a href="https://www.youtube.com/@Macmillantrust" target="_blank"
                            class="btn SSLinks btn-icon btn-sm text-green-600 hover:text-white border border-gray-800 rounded-md"
                            target="_blank"><i data-feather="youtube" class="h-5 w-5"></i></a></li>
                    <li class="inline"><a href="https://pin.it/4NQJzz8d4"
                            class="btn SSLinks btn-icon btn-sm text-green-600 hover:text-white border border-gray-800 rounded-md"
                            target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                <path fill="currentColor" fill-rule="evenodd"
                                    d="M12 2C6.477 2 2 6.477 2 12c0 4.236 2.636 7.854 6.355 9.311c-.087-.791-.166-2.004.035-2.868c.182-.78 1.173-4.97 1.173-4.97s-.299-.6-.299-1.485c0-1.391.806-2.428 1.81-2.428c.852 0 1.264.64 1.264 1.408c0 .858-.546 2.14-.828 3.33c-.236.995.5 1.806 1.481 1.806c1.777 0 3.143-1.874 3.143-4.579c0-2.394-1.72-4.068-4.177-4.068c-2.844 0-4.515 2.134-4.515 4.34c0 .859.331 1.78.744 2.281a.3.3 0 0 1 .07.287c-.076.316-.245.995-.278 1.135c-.044.182-.145.221-.334.133c-1.25-.581-2.03-2.407-2.03-3.874c0-3.155 2.291-6.051 6.607-6.051c3.469 0 6.165 2.472 6.165 5.775c0 3.447-2.173 6.22-5.19 6.22c-1.012 0-1.965-.526-2.291-1.149c0 0-.502 1.91-.623 2.378c-.226.868-.835 1.958-1.243 2.622c.936.289 1.93.445 2.96.445C17.524 22 22 17.522 22 12S17.523 2 12 2" />
                            </svg>
                        </a></li>
                </ul><!--end icon-->
            </div><!--end grid-->
        </div><!--end container-->
    </div>
</footer>