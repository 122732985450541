<!-- Switcher -->
<div class="fixed top-1/4 -left-2 z-3">
    <span class="relative inline-block rotate-90">
        <input type="checkbox" class="checkbox opacity-0 absolute" id="chk" (change)="changeMode()" />
        <label
            class="label bg-slate-900 dark:bg-white shadow dark:shadow-gray-800 cursor-pointer rounded-full flex justify-between items-center p-1 w-14 h-8"
            for="chk">
            <i class="uil uil-moon text-[20px] text-yellow-500"></i>
            <i class="uil uil-sun text-[20px] text-yellow-500"></i>
            <span class="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] w-7 h-7"></span>
        </label>
    </span>
</div>
<!-- Switcher -->

<!-- LTR & RTL Mode Code -->
<div class="fixed top-1/3 -left-2 z-50">
    <a href="javascript:void(0);" id="switchRtl">
        <span
            class="py-1 px-3 relative inline-block rounded-b-md -rotate-90 bg-white dark:bg-slate-900 dark:text-white shadow-md dark:shadow dark:shadow-gray-800 font-semibold rtl:block ltr:hidden" (click)="toggleMode()">LTR</span>
        <span
            class="py-1 px-3 relative inline-block rounded-b-md -rotate-90 bg-white dark:bg-slate-900 dark:text-white shadow-md dark:shadow dark:shadow-gray-800 font-semibold ltr:block rtl:hidden" (click)="toggleMode()">RTL</span>
    </a>
</div>
<!-- LTR & RTL Mode Code -->

<!-- Back to top -->
<a href="#" (click)="topFunction()" id="back-to-top" class="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 leading-9 text-center bg-green-600 text-white justify-center items-center"><i class="uil uil-arrow-up"></i></a>
