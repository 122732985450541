import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListingService {

  constructor(
    private http: HttpClient
  ) { }
  propertiesList(type: string, filter: any, limit: any, page: any,) {
    filter.page = page;
    filter.limit = limit;
    const queryParams = this.buildQueryString(filter)
    let path = ''
    if (type == 'rental') {
      path = `${environment.apiUrl}listing/rent?${queryParams}`
    }
    else if (type == 'sale') {
      path = `${environment.apiUrl}listing/sale?${queryParams}`
    }
    return this.http.get(path)

  }
  buildQueryString(data: any): string {
    const params = [];

    for (const [key, value] of Object.entries(data)) {
      if (value) {
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`);
      }
    }
    return params.join('&');
  }
  getCategories(type: string) {
    let params = new HttpParams();
    params = params.append('type', type);
    let path = `${environment.apiUrl}category`
    return this.http.get(path, { params: params });
  }
  getCities() {
    let path = `${environment.apiUrl}dubia-city`
    return this.http.get(path);
  }
  getLocations(city_id: number, key?: string) {
    let path = `${environment.apiUrl}location?city_id=${city_id}`
    if (key) {
      path = `${environment.apiUrl}location?city_id=${city_id}&keyword=${key}`
    }
    return this.http.get(path);
  }

  propertyDetail(id: number | string) {
    const path = `${environment.apiUrl}listing/${id}`;
    return this.http.get(path);
  }

  inquiry(type: string, data: any) {
    let path = '';
    if (type == 'contact') {
      path = `${environment.apiUrl}inquiry/contact`
    }
    else if (type == 'inquiry') {
      path = `${environment.apiUrl}inquiry`
    }
    return this.http.post(path, data);
  }
}
