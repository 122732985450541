<div class="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700" [ngClass]="{'px-6 pb-6': !isModal}">
    <h3 class="pt-4 text-2xl leading-normal font-medium" [ngClass]="{'border-b border-gray-300 px-6': isModal}">
        {{isModal?'ENQUIRE': 'Contact Us'}}</h3>
    @if(isLoading && ownLoading){
    <div class="loaderOuter">
        <div class="loader"></div>
    </div>
    }
    <form [formGroup]="enquiryForm" (ngSubmit)="saveEnquiryForm()" [ngClass]="{'p-6': isModal}">
        <div class="grid lg:grid-cols-12 lg:gap-6">
            <div class="lg:col-span-6 mb-5">
                <label for="first_name" class="font-medium">First Name*</label>
                <input first_name="first_name" id="first_name" type="text" class="form-input mt-2"
                    placeholder="Enter your first name" formControlName="first_name"
                    [class.border-red-500]="enquiryForm.get('first_name').invalid && (enquiryForm.get('first_name').dirty || enquiryForm.get('first_name').touched)">

                @if(enquiryForm.get('first_name').invalid && (enquiryForm.get('first_name').dirty ||
                enquiryForm.get('first_name').touched) && enquiryForm.get('first_name').errors?.['required']) {
                <p class="mt-2 text-sm text-red-600 dark:text-red-500">First name is required</p>
                }
            </div>

            <div class="lg:col-span-6 mb-5">
                <label for="last_name" class="font-medium">Last Name*</label>
                <input last_name="last_name" id="last_name" type="text" class="form-input mt-2"
                    placeholder="Enter your last_name" formControlName="last_name"
                    [class.border-red-500]="enquiryForm.get('last_name').invalid && (enquiryForm.get('last_name').dirty || enquiryForm.get('last_name').touched)">

                @if(enquiryForm.get('last_name').invalid && (enquiryForm.get('last_name').dirty ||
                enquiryForm.get('last_name').touched) && enquiryForm.get('last_name').errors?.['required']) {
                <p class="mt-2 text-sm text-red-600 dark:text-red-500">Last name is required</p>
                }
            </div>
        </div>

        <div class="grid lg:grid-cols-12 lg:gap-6">

            <div class="lg:col-span-6 mb-5">
                <label for="email" class="font-medium">Email*</label>
                <input name="email" id="email" type="email" class="form-input mt-2" placeholder="Enter your email"
                    formControlName="email"
                    [class.border-red-500]="enquiryForm.get('email').invalid && (enquiryForm.get('email').dirty || enquiryForm.get('email').touched)">

                @if(enquiryForm.get('email').invalid && (enquiryForm.get('email').dirty ||
                enquiryForm.get('email').touched) && enquiryForm.get('email').errors?.['required']) {
                <p class="mt-2 text-sm text-red-600 dark:text-red-500">Email is required</p>
                }

                @if(enquiryForm.get('email').errors?.['email'] && enquiryForm.get('email').invalid &&
                enquiryForm.get('email').touched) {
                <p class="mt-2 text-sm text-red-600 dark:text-red-500">Invalid Email</p>
                }
            </div>

            <div class="lg:col-span-6 mb-5">
                <label for="mobile" class="font-medium">Mobile*</label>
                <input name="mobile" id="mobile" class="form-input mt-2" placeholder="Enter your mobile number"
                    formControlName="mobile"
                    [class.border-red-500]="enquiryForm.get('mobile').invalid && (enquiryForm.get('mobile').dirty || enquiryForm.get('mobile').touched)">

                @if(enquiryForm.get('mobile').invalid && (enquiryForm.get('mobile').dirty ||
                enquiryForm.get('mobile').touched) &&
                enquiryForm.get('mobile')?.hasError('phoneNumber')) {
                <p class="mt-2 text-sm text-red-600 dark:text-red-500">{{
                    enquiryForm.get('mobile')?.getError('phoneNumber') }}</p>
                }
            </div>
        </div>

        <div class="grid grid-cols-1">
            <div class="mb-5">
                <label for="comments" class="font-medium">Your Comment</label>
                <textarea id="note" rows="5" class="form-input mt-2 textarea" placeholder="Write your message here...."
                    formControlName="note"></textarea>
            </div>
        </div>
        <button type="submit" id="submit" name="send" class="btn bg-green-600 text-white rounded-md"> Send</button>
    </form>
</div>