import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-features',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './features.component.html',
  styleUrl: './features.component.scss'
})
export class FeaturesComponent {

  featureData = [
    {
      icon:'uil uil-estate',
      title:'Evaluate Property',
      desc:`"Discover the true value of your property instantly with our 'Evaluate Property' tool. Get an estimate based on key factors like location, size, and market trends. Sell, refinance, or just satisfy your curiosity – it's easy and free!"`
    },
    {
      icon:'uil uil-bag',
      title:'Meeting with Agent',
      desc:'"Schedule a meeting with our agent today. Get expert guidance and personalized advice on your property needs."'
    },
    {
      icon:'uil uil-key-skeleton',
      title:'Close the Deal',
      desc:`"Ready to seal the deal? Let's finalize your property transaction with confidence and efficiency. Contact us to close the deal swiftly and smoothly."`
    },
  ]

}
