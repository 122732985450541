import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { environment } from '../../environments/environment';

export function phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const phoneNumber = control.value;
        if (phoneNumber == '+' + environment.defaultCountry.phoneCode) {
            return { phoneNumber: 'Phone number is required.' };
        }
        const phonePattern = /^\+971[0-9]{9}$/;
        return phonePattern.test(phoneNumber) ? null : !phoneNumber.includes('+' + environment.defaultCountry.phoneCode) ? { phoneNumber: `Phone number must starts with + ${environment.defaultCountry.phoneCode}` } : { phoneNumber: 'Invalid phone number' };;
    };
}
